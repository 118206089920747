import Vue from 'vue';
import axios from './plugins/axios'; // ajax library
import store from './plugins/store'; // vue local database
// import vuetify from './plugins/vuetify'; // vue ux/ui
import vueCookies from './plugins/vue-cookies'; // vue cookie
import prototype from './plugins/prototype';
import naverPay from './plugins/naver-pay';
import components from './plugins/components';

window.Vue = Vue;
window.Vue.data = {};
window.Vue.methods = {};
window.Vue.watch = {};
window.Vue.computed = {};
window.Vue.created = [];
window.Vue.mounted = [];

window.addEventListener('DOMContentLoaded', () => {
    window.Vue.app = new Vue({
        store,
        // vuetify,
        data: window.Vue.data,
        methods: window.Vue.methods,
        watch: window.Vue.watch,
        computed: window.Vue.computed,
        created: function(){
            console.log("app-created");
            for(var i=0; i<window.Vue.created.length; i++){
                window.Vue.created[i](this);
            }
        },
        mounted: function(){
            console.log("app-mounted");
            for(var i=0; i<window.Vue.mounted.length; i++){
                window.Vue.mounted[i](this);
            }
        }
    }).$mount('#vue-app');
});
