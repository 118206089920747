import { render, staticRenderFns } from "./hello-world.vue?vue&type=template&id=5c9bdbc7&"
import script from "./hello-world.vue?vue&type=script&lang=js&"
export * from "./hello-world.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/uxitem007/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5c9bdbc7')) {
      api.createRecord('5c9bdbc7', component.options)
    } else {
      api.reload('5c9bdbc7', component.options)
    }
    module.hot.accept("./hello-world.vue?vue&type=template&id=5c9bdbc7&", function () {
      api.rerender('5c9bdbc7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "public_html/components/test/hello-world.vue"
export default component.exports