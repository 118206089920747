<template>
    <div>
        Hello World 11
        <div id="practice">
            <input v-model="userId" />
            <input v-model="userPw" />
            <button type="button" class="buttonClicked" @click="onClickLogin()">login</button>
            <div v-for="userData in userDatas" key="userData.reUserId">
                {{ userData.reUserId }}
                {{ userData.reUserPw }}
            </div>
        </div>
    </div>
</template>

<script>
export default{
    data() {
        return{
            userId: "",
            userPw: "",
            userDatas: []
        }

    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.getTest().then((res) => {
                console.log(res);
            })
        },
        getTest(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/v1/test');

                    resolve(res);
                }
                catch(error){
                    reject(error);
                }
            });
        },
        onClickLogin() {
            return new Promise(async (resolve, reject) => {
                try{
                    console.log(this.userId+":"+this.userPw);
                    var userId = this.userId;
                    var userPw = this.userPw;

                    var res = await this.$http.post('/api/v1/test', { userId, userPw })
                    .then((res)=>{
                        console.log(res.data.message);
                        console.log(res.data.message[1]);
                        console.log(typeof res.data.success)
                        if(res.data.success){
                            var reUserId = res.data.message[0];
                            var reUserPw = res.data.message[1];
                            this.userDatas.push({reUserId, reUserPw});
                        }else{
                            alert("fail!!")
                        }
                    })
                    resolve();

                }catch(error){
                    reject(error);
                }
            })
        }
    }
}
</script>
